import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import Sidebar from '../../components/articles/sidebar'
import SocialSharing from '../../components/sharing'
import { Link, graphql } from "gatsby"
import { data } from 'autoprefixer'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { INLINES, BLOCKS, MARKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
      return (
        <a href={uri} className="underline">
          {children}
        </a>
      )
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2>{children}</h2>
    },
  },
}

const ArticlePost = ({ data, location }) => {
    console.log("data template : ", data);
    console.log("location : ", location);
    return (
        <>
            <div className='container grid grid-cols-3 gap-4'>
                <div className='col-span-2 border-r border-black pr-12'>
                    <div>
                        <img 
                            src={`https://api.clubmella.com/storage/images_disk/${data.gogobackend.article.image}`}
                            alt="Sample photo" 
                            layout="fullWidth"
                            placeholder="blurred"
                        />
                    </div>
                    <div className='col-span-1 flex flex-col space-y-3 text-article leading-normal text-skin-base'>
                        <h1 className='text-2xl mt-6 font-bold'>{data.gogobackend.article.title_en}</h1>
                        <p className='text-sm text-skin-muted mt-3'>{data.gogobackend.article.created_at} | <SocialSharing url={location.href} /></p>
                        {/* <div>{renderRichText(data.contentfulPost.content.raw, options)}</div> */}

                        <div dangerouslySetInnerHTML={{__html: data.gogobackend.article.content_en}} />
                    </div>
                </div>
                <div className='pl-6'>
                
                </div>
            </div>
        </>
        
    )
}

export const queryPrimary = graphql`

query MyQuery($slug: String) {
  gogobackend {
    article(
        slug: $slug
        ) {
          content_en
          content_zh
          page_location
          slug
          title_en
          title_zh
          subtitle_en
          subtitle_zh
          category_en
          category_zh
          author_en
          author_zh
          image
      }
    }
  }
`

export default ArticlePost

